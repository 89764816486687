import generateOrderItemHash from './generateOrderItemHash';

type GroupedOrderItem<T> = {
  id: string;
  orderItemsUuids: string[];
  amount: number;
  price: number;
  productTitle: string;
  productPrintTitle?: string;
  productUuid?: string;
  comment?: string;
  discount: number;
  getsby_discount: number;
  is_canceled: boolean;
  productGroupType?: string;
  productGroupTitle?: string;
  order?: string;
  modifierItems?: T[];
};

type OrderItemInput<T> = {
  uuid: string;
  product_price: number;
  product_title: string;
  product_print_title?: string;
  product_uuid?: string;
  comment?: string;
  discount: number;
  getsby_discount: number;
  is_canceled: boolean;
  product_group_type: string;
  productGroup?: {
    title: string;
    alias?: string;
    order_nr?: number;
  };
  product?: {
    order_nr?: number;
  };
  modifierItems?: T[];
};

export default function groupOrderItems<T>(
  orderItems: OrderItemInput<T>[]
): GroupedOrderItem<T>[] {
  const grouped: GroupedOrderItem<T>[] = [];
  for (const orderItem of orderItems) {
    const currentHash = generateOrderItemHash(orderItem);
    const index = grouped.findIndex((i) => i.id === currentHash);
    if (index === -1) {
      grouped.push({
        id: currentHash,
        orderItemsUuids: [orderItem.uuid],
        amount: 1,
        price: orderItem.product_price || 0,
        productTitle: orderItem.product_title,
        productPrintTitle: orderItem.product_print_title,
        productUuid: orderItem.product_uuid,
        comment: orderItem.comment,
        discount: orderItem.discount || 0,
        getsby_discount: orderItem.getsby_discount || 0,
        is_canceled: orderItem.is_canceled || false,
        productGroupType: orderItem.product_group_type || 'OTHER',
        productGroupTitle:
          orderItem.productGroup && orderItem.productGroup.alias
            ? orderItem.productGroup.alias
            : orderItem.productGroup && orderItem.productGroup.title
            ? orderItem.productGroup.title
            : '',
        order:
          orderItem.product && orderItem.productGroup
            ? `${orderItem.productGroup.order_nr || 0}-${
                orderItem.product.order_nr || 0
              }`
            : undefined,
        modifierItems: orderItem.modifierItems || [],
      });
    } else {
      grouped[index].amount++;
      grouped[index].orderItemsUuids.push(orderItem.uuid);
    }
  }
  return grouped.sort((a, b) => {
    if (a.order && b.order) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
    }
    return 0;
  });
}
