interface ModifierItem {
  modifier_item_uuid?: string;
}

type OrderItem<T extends ModifierItem> = {
  product_uuid?: string;
  product_title?: string;
  comment?: string;
  is_canceled?: boolean;
  modifierItems?: T[];
};

function hashCode(input: string) {
  let hash = 0,
    i,
    chr;
  if (input.length === 0) return hash.toString();
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash.toString();
}

/**
 * Generates a base64 encoded string from an OrderItem object
 * @param orderItem the OrderItem to hash
 * @returns
 */
export default function generateOrderItemHash<T extends ModifierItem>(
  orderItem: OrderItem<T>
): string {
  if (!orderItem) {
    throw new Error('Missing orderItem!');
  }
  const objString = JSON.stringify({
    product: orderItem.product_uuid || orderItem.product_title || '',
    comment: orderItem.comment || '',
    is_canceled: orderItem.is_canceled || false,
    modifierItems: orderItem.modifierItems
      ? orderItem.modifierItems.map((mi) => mi.modifier_item_uuid)
      : [],
  });
  return hashCode(objString);
}
